import React, { useState } from 'react'
import profilePic from '../../assets/me.png'
import { MdOutlineEmail } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { LuMessageSquare } from "react-icons/lu";
import { GoDownload } from "react-icons/go";
import { FaInstagram, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { TbWorldWww } from "react-icons/tb";
import resume from '../../assets/resume.pdf'
import './styles.css'

function Card() {
    const [rotate, setRotate] = useState(false);
    const [rotateDeg, setRotateDeg] = useState(0);

    const handleRotate = () => {
        if (!rotate && rotateDeg < 180) {
            const intervalId = setInterval(() => {
                setRotateDeg((prevDeg) => {
                    const nextDeg = prevDeg + 1;
                    console.log(nextDeg); // Log the intermediate value

                    if (nextDeg >= 180) {
                        clearInterval(intervalId); // Stop the interval when rotateDeg reaches 180
                    }

                    return nextDeg;
                });
            }, 5); // Introduce a delay between increments (adjust as needed)
        }

        if (rotate && rotateDeg > 0) {
            const intervalId = setInterval(() => {
                setRotateDeg((prevDeg) => {
                    const nextDeg = prevDeg - 1;
                    console.log(nextDeg); // Log the intermediate value

                    if (nextDeg <= 0) {
                        clearInterval(intervalId); // Stop the interval when rotateDeg reaches 0
                    }

                    return nextDeg;
                });
            }, 5); // Introduce a delay between increments (adjust as needed)
        }

        setRotate((prevRotate) => !prevRotate);
    };


    console.log(rotate)
    console.log(rotateDeg)

    return (
        <div className='Card'>
            <div className='content-card' style={{ transform: `rotateY(${rotateDeg}deg)` }} >
                <button onClick={handleRotate}><FaArrowRotateLeft size={36} color='white' /></button>
                <div className='card-front' style={{ display: rotateDeg > 90 ? "none" : "flex" }}>
                    <div>
                        <img src={profilePic} width={125} alt='profile img' />
                        <h3>Christian Hernandez</h3>
                        <h5>Software Engineer</h5>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <a href='mailto:hernandezchristian94@gmail.com'>
                                    <MdOutlineEmail size={24} color='black' />
                                </a>
                                <p>Email</p>
                            </li>
                            <li>
                                <a href='tel:+19095015252'>
                                    <IoPhonePortraitOutline size={24} color='black' />
                                </a>
                                <p>Call</p>
                            </li>
                            <li>
                                <a href='tel:+19095015252'>
                                    <LuMessageSquare size={24} color='black' />
                                </a>
                                <p>Message</p>
                            </li>
                            <li>
                                <a href={resume} download="resume.pdf">
                                    <GoDownload size={24} color='black' />
                                </a>
                                <p>Resume</p>
                            </li>
                        </ul>
                        <p>Email</p>
                        <a href='mailto:hernandezchristian94@gmail.com'>hernandezchristian94@gmail.com</a>
                        <p className='number'>Phone Number</p>
                        <a href='tel:+19095015252'>(909) 501-5252</a>
                        <hr />
                        <ul>
                            <li><a href='https://www.instagram.com/h.christian.h1?igsh=YzVkODRmOTdmMw%3D%3D'><FaInstagram size={24} color='black' /></a></li>
                            <li><a href='https://www.linkedin.com/in/christian-hernandez-967740247/'><FaLinkedinIn size={24} color='black' /></a></li>
                            <li><a href='https://chernandez148.github.io/portfolio_v6/'><TbWorldWww size={24} color='black' /></a></li>
                            <li><a href='https://github.com/chernandez148'><FaGithub size={24} color='black' /></a></li>
                        </ul>
                    </div>
                </div>
                <div className='card-back' style={{ display: rotateDeg < 90 ? "none" : "flex" }}>
                    <h3>Project List</h3>
                    <ul>
                        <li>
                            <a href='https://sbcapprenticeship.com/'>San Bernardino Cuts Barbering & Cosmetology Institute</a>
                            <p>
                                Explore San Bernardino Cuts Barbering & Cosmetology Institute's user-friendly website, powered by React.js and Flask. Discover detailed insights into barbering and cosmetology programs with easy navigation. Connect seamlessly through intuitive forms for inquiries and pre-approval applications, receiving prompt automated email notifications. The responsive design underscores the institute's commitment to accessible and top-notch beauty education.
                            </p>
                        </li>
                        <li>
                            <a href='https://christianhernandez.ca/'>Dessert Atanomy Mock-up</a>
                            <p>
                                A visually appealing mock-up for a dessert company using React.js with modern CSS, including parallax styling. Incorporate vibrant imagery of desserts, use clean layouts, and add interactive components for an engaging experience. Ensure a responsive design for optimal viewing on different devices.
                            </p>
                        </li>
                        <li>
                            <a href='https://chernandez148.github.io/icebraker-bartending/'>Icebreaker Bartending Mock-up</a>
                            <p>
                                A sleek bartending website mock-up using React.js and modern CSS. Highlight a variety of cocktails with appealing images, ensuring easy navigation through a clean layout. Integrate subtle animations and transitions for a polished look. Opt for a color palette that complements the bar ambiance, and prioritize a responsive design for seamless viewing on all devices. Capture the essence of bartending expertise and showcase diverse drink offerings for a visually engaging yet straightforward design.
                            </p>
                        </li>
                    </ul>
                    <a href='https://chernandez148.github.io/portfolio_v6/'>View More</a>
                </div>
            </div>
        </div>
    )
}

export default Card